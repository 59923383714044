var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view-producer" }, [
    _c("header", { staticClass: "header" }, [
      _c("div", { staticClass: "container headerContainer" }, [
        _vm._m(0),
        _c("div", { staticClass: "headerContainer__alignRight" }, [
          _c("span", [
            _vm._v("Generate a .csv file with the emails of your customers"),
          ]),
          _c(
            "a",
            {
              staticClass: "btn btn--primary btn--sm",
              on: { click: _vm._exportCustomerEmails },
            },
            [_vm._v("DOWNLOAD")]
          ),
        ]),
      ]),
    ]),
    _c(
      "section",
      {
        staticClass: "section",
        class: { "h-mt-160 section--flexCenter": _vm.sales.length === 0 },
      },
      [
        _vm.isLoading
          ? _c(
              "div",
              { staticClass: "container container--fullWidth" },
              [_c("VLoadSpinner")],
              1
            )
          : !_vm.isLoading && _vm.sales.length === 0
          ? _c("div", { staticClass: "container container--fullWidth" }, [
              _c("h2", [_vm._v("You haven't made any sales yet.")]),
              _c("p", [
                _vm._v("Don't get discouraged, Rome wasn't built in a day..."),
              ]),
            ])
          : _c(
              "div",
              { staticClass: "container container--fullWidth wrapper--filled" },
              [
                _c("table", { staticClass: "table--order" }, [
                  _vm._m(1),
                  _c(
                    "tbody",
                    _vm._l(_vm.sales, function (sale) {
                      return _c("tr", { staticClass: "order" }, [
                        _c(
                          "td",
                          { staticClass: "table-cell order__id" },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "OrderDetails",
                                    params: { id: sale.id },
                                  },
                                },
                              },
                              [_vm._v(_vm._s(sale.id))]
                            ),
                          ],
                          1
                        ),
                        _c("td", { staticClass: "table-cell h-hide@phone" }, [
                          _vm._v(_vm._s(sale.bought_beats_light.length)),
                        ]),
                        _c("td", { staticClass: "table-cell order__date" }, [
                          _vm._v(
                            _vm._s(_vm._f("moment")(sale.date, "MMMM Do, YYYY"))
                          ),
                        ]),
                        _c("td", { staticClass: "table-cell order__date" }, [
                          _vm._v(
                            "$" +
                              _vm._s(
                                _vm._calculateTotal(sale.bought_beats_light)
                              )
                          ),
                        ]),
                      ])
                    }),
                    0
                  ),
                ]),
                _c("pagination", {
                  attrs: {
                    "page-index": _vm.pageIndex,
                    numberOfPages: _vm.orderPages,
                  },
                  on: { changePage: _vm._fetchNewOrders },
                }),
              ],
              1
            ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "headerContainer__heading" }, [
      _c("h3", [_vm._v("Sales ")]),
      _c("h1", [_vm._v("Orders ")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("ORDER ID")]),
        _c("th", { staticClass: "h-hide@phone" }, [_vm._v("QTY")]),
        _c("th", [_vm._v("DATE")]),
        _c("th", [_vm._v("TOTAL")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }